import { inject, ref, computed, InjectionKey, Ref, provide } from 'vue';

import { commands } from '@/connectors/litium/search';

import useContext from '@/composables/useContext';
import useGiftCard from '@/composables/useGiftCard';
import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

const searchQueryKey: InjectionKey<Ref<any>> = Symbol('searchQuery');
const searchResultKey: InjectionKey<Ref<any>> = Symbol('searchResult');
const searchKey: InjectionKey<(searchPhrase: string) => {}> = Symbol('search');



export default function () {
  const searchTimeout: any = ref(null);

  const { ga4 } = useContext();
  const { activeGiftCard } = useGiftCard();
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();

  const error = ref('');

  const searchQuery = ref('');
  provide(searchQueryKey, searchQuery);

  const searchResult: any = ref(null);
  provide(searchResultKey, searchResult);

  provide(searchKey, (searchPhrase: string) => {
    searchQuery.value = searchPhrase;
    error.value = '';

    if (!searchPhrase) {
      searchResult.value = null;
    } else {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
      }
      searchTimeout.value = setTimeout(async () => {
        ga4.search(searchPhrase);

        const categorySystemId = activeGiftCard.value?.firstCategory?.categoryId;
        if (!categorySystemId) {
          throw 'MISSING_CATEGORY';
        }
        try {
          const result = await executeCommand(commands.searchGifts, {
            categorySystemId,
            searchPhrase,
          });
          searchResult.value = result;
        } catch (err) {
          searchResult.value = null;
          console.error(err);

          if (err == 'MISSING_CATEGORY') {
            error.value = websiteText('getmygift__search__missing_category').value;
            return;
          }

          error.value = websiteText('getmygift__search__unknownerror').value;
        }
      }, 200);
    }

    return {};
  });

  function clearSearch() {
    searchQuery.value = '';
    searchResult.value = null;
  }

  return {
    searchQuery,
    searchResult,
    error,
    clearSearch,
  };
}

export function getContext() {
  return {
    searchQuery: inject(searchQueryKey, ref<any>(<any>'')),
    searchResult: inject(searchResultKey, ref<any>(<any>[])),
    search: inject(searchKey, (searchPhrase: string) => {}),
  };
}
