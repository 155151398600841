<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";

import { freeze, unfreeze } from "@motillo/ui-scroll-freeze";

import useContext from "@/composables/useContext";
import useWebsiteTexts from "@/composables/useWebsiteTexts";
import useSearch from "@/composables/useSearch";
import useEventListener from "@/composables/useEventListener";
import { deviceWidths } from "../../constants";

import CloseIcon from "@/components/icons/CloseIcon.vue";
import SearchBar from "@/components/search/SearchBar.vue";
import SearchResult from "@/components/search/SearchResult.vue";

const { route } = useContext();
const { websiteText } = useWebsiteTexts();
const { searchQuery, error, searchResult, clearSearch } = useSearch();
const { useEmitterEventListener } = useEventListener();

const isOpen = ref(false);
const searchEl = ref(null);

const zeroHitsCaption = computed(() => {
  if (searchQuery.value && searchResult.value?.products?.length === 0) {
    return websiteText("search__no_hits_caption").value?.replace("{0}", searchQuery.value);
  }
  return "";
});

function open() {
  freeze("Search");
  isOpen.value = true;
  setTimeout(() => {
    searchEl.value?.classList?.add("open");
  }, 0);
}

function close() {
  unfreeze("Search");
  window && window.innerWidth < deviceWidths.phoneMaxWidth
    ? setTimeout(() => {
        isOpen.value = false;
      }, 250)
    : (isOpen.value = false);

  searchEl.value?.classList?.remove("open");
  clearSearch();
}

onMounted(() => {
  watch(
    () => route.pathname,
    () => {
      close();
    }
  );
});

useEmitterEventListener("Search:Open", open);
</script>

<template>
  <div class="search" v-if="isOpen" ref="searchEl">
    <div class="search__wrapper">
      <div class="search__actions">
        <div class="search__page-title">
          {{ websiteText("getmygift__search__pagetitle").value }}
        </div>
        <div class="search__close" @click="close()">
          <close-icon class="search__close-icon" />
        </div>
      </div>
      <div class="search__content">
        <SearchBar class="search__search-bar" />
        <SearchResult />
        <div v-if="zeroHitsCaption" class="search__content__zero-hits">
          {{ zeroHitsCaption }}
        </div>
        <div v-if="error" class="search__content__error">
          {{ websiteText("getmygift__search__unknownerror").value }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.search {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  background-color: var(--color-gray-1440);
  width: 100%;
  transform: translateX(100%);
  @media (--phone) {
    transition: transform 0.25s;
  }
}

.search.open {
  transform: translateX(0);
}

.search__wrapper {
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.search__actions {
  margin: 1.5rem auto;
  max-width: 950px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search__page-title {
  text-align: center;
  font-size: 16px;
  font-family: var(--font-mulish-bold);
  margin: auto;
  letter-spacing: 0.75px;
}

.search__actions,
.search__content {
  padding: 0 1.5rem;
}

.search__content__zero-hits,
.search__content__error {
  font-size: 24px;
  text-align: center;
}

.search__search-bar {
  max-width: 700px;
  position: relative;
  margin: 1rem 0 1rem 0;
}

.search__close {
  position: relative;
  display: block;
  margin-right: -4px;
}

.search__close:hover .search__close-icon {
  color: var(--color-gray-900);
}

.search__close-icon {
  color: var(--color-gray-720);
  cursor: pointer;
  width: 30px;
  height: 30px;
}

@media (--tabletAndDesktop) {
  .search {
    left: 0;
    transform: translateX(0);
    width: 100%;
  }

  .search__page-title {
    font-size: 25px;
  }

  .search__search-bar {
    margin: 1.5rem auto 1rem auto;
  }
}
</style>
