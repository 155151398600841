<script setup lang="ts">
  import { computed } from 'vue';

  import useContext from '@/composables/useContext';
  import useGiftCard from '@/composables/useGiftCard';
  import useWebsiteTexts from '@/composables/useWebsiteTexts';

  const { channel, navigate } = useContext();
  const { removeAllGiftCards } = useGiftCard();
  const { websiteText } = useWebsiteTexts();
  const rootPath = computed(() => {
    return channel.value?.rootPath || '';
  });

  async function logout() {
    try {
      await removeAllGiftCards();

      if (rootPath.value) {
        await navigate(rootPath.value);
      }
    } catch (ex) {
      console.warn(ex);
      alert(websiteText('global__failed_to_remove_giftcard').value);
    }
  }
</script>

<template>
  <div
    class="logout"
    @click="logout()">
    {{ websiteText('header_giftcardlogout').value }}
  </div>
</template>

<style>
  .logout {
    cursor: pointer;
    user-select: none;
  }
</style>
