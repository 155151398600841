import create from './main';
import Worker from 'worker-loader!./web-worker';

const litiumBaseUrl = () => {
  if (process.env.USE_LITIUM_URL_REWRITE === 'true' && typeof window === 'object') {
    var hostname = window.location.hostname.replace(/\/$/, '').replace('www.', '');
    return decodeURI(`${window.location.protocol}//litium.${hostname}`);
  }

  return process.env.LITIUM_URL || '';
};

const app = create(
  {
    litiumBaseUrl: litiumBaseUrl(),
  },
  () => new Worker()
);

app.mount('#app');