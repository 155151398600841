export function buildQueryParams(query: any) {
  const querySegments: any = [];

  for (const parameter in query) {
    const value = query[parameter];
    if (value) {
      querySegments.push(`${parameter}=${value}`);
    }
  }

  return querySegments.join('&');
}

export function buildUrlPathWithQuery(path: string, query: any) {
  try {
    const queryParams: any = buildQueryParams(query);
    return queryParams ? `${path}?${queryParams}` : path;
  } catch (err) {
    console.error(err);
    return path;
  }
}
