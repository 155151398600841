<script>
import DesktopHeader from '@/components/header/DesktopHeader';
import DesktopHeaderLinks from "@/components/header/DesktopHeaderLinks";
import MobileHeader from '@/components/header/MobileHeader';

export default {
  components: {
    DesktopHeader,
    DesktopHeaderLinks,
    MobileHeader
  },
  computed: {
    header() {
      return this.$header;
    },
    linkList() {
      return this.header?.fields?.LinkList || [];
    },
    logoType() {
      return this.$channel.headerLogotype || '';
    },
    rootPath() {
      return this.$channel.rootPath || '';
    },
    rootPathRedirectUrl() {
      return this.$channel.rootPathRedirectUrl || '';
    },
    logoTypeUrl() {
      return this.logoType ? this.$toLitiumMediaUrl(this.logoType) : '';
    },
    links() {
      if (!this.linkList) {
        return [];
      }

      const links = this.linkList
        .filter((l) => l.url && l.fields._name)
        .map((l) => ({
          caption: l.fields._name,
          url: l.url !== this.rootPath ? l.url : this.rootPathRedirectUrl
        }));

      return links;
    }
  }
};
</script>

<template>
  <div class="site-header">
    <desktop-header
      class="site-header__desktop"
      :logoUrl="logoTypeUrl"
    />
    <mobile-header
      class="site-header__mobile"
      :logoUrl="logoTypeUrl"
      :links="links"
    />
  </div>
  <div class="site-header-links">
    <desktop-header-links class="site-header__desktop-links" :links="links" />
  </div>
</template>

<style>
.site-header {
  background-color: #ebe5da;
  position: sticky;
  top: 0;
  z-index: 900;
}

@media (--phoneAndTablet) {
  .site-header__desktop {
    display: none;
  }
  
  .site-header-links{
    display: none;
  }
}

@media (--desktop) {
  .site-header__mobile {
    display: none;
  }
}
</style>
