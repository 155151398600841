<script setup lang="ts">
import { ref, onMounted, watch } from "vue";

import useWebsiteTexts from "@/composables/useWebsiteTexts";
import { getContext } from "@/composables/useSearch";

import SearchIcon from "@/components/icons/SearchIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

const { websiteText } = useWebsiteTexts();
const { search } = getContext();

const searchQuery = ref("");
const searchInput: any = ref(null);

function clear() {
  searchQuery.value = "";
}

onMounted(() => {
  searchInput.value?.focus();

  watch(
    () => searchQuery.value,
    () => {
      search(searchQuery.value);
    }
  );
});
</script>

<template>
  <div class="search-bar">
    <div class="search-bar__form">
      <input
        type="text"
        :placeholder="websiteText('header_searchplaceholder').value"
        v-model="searchQuery"
        class="search-bar__input"
        ref="searchInput"
      />
      <div class="search-bar__actions">
        <div class="search-bar__clear" v-if="searchQuery" @click="clear()">
          <close-icon class="search-bar__clear-icon" />
        </div>
        <div class="search-bar__search">
          <search-icon class="search-bar__search-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.search-bar__form {
  position: relative;
}

input[type="text"].search-bar__input {
  background-color: transparent;
  padding: 30px 70px 6px 0;
  color: var(--color-gray-720);
  letter-spacing: 0.72px;
}

input[type="text"].search-bar__input,
input[type="text"].search-bar__input:focus {
  border: none;
  border-bottom: 1px solid var(--color-gray-720);
}

.search-bar__clear {
  position: absolute;
  right: 10px;
  height: 20px;
  bottom: 5px;
}

.search-bar__clear,
.search-bar__search {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5px;
}

.search-bar__clear:hover,
.search-bar__search:hover {
  cursor: pointer;
  color: var(--color-gray-900);
}

.search-bar__search {
  right: 0;
}

.search-bar__clear {
  right: 30px;
}

.search-bar__search-icon {
  height: 20px;
}

.search-bar__clear-icon {
  width: 14px;
  height: 14px;
}
</style>
