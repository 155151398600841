<script>
import { deviceWidths } from '../../constants';

import DrawerLayer from '@motillo/ui-drawer-layer';
import ActiveGiftCard from '@/components/header/ActiveGiftCart.vue';
import MobileHeaderMenu from '@/components/header/MobileHeaderMenu.vue';
import FavoritesHeaderLink from '@/components/header/FavoritesHeaderLink.vue';

export default {
  components: {
    ActiveGiftCard,
    MobileHeaderMenu,
    FavoritesHeaderLink,
    DrawerLayer
  },
  props: {
    logoUrl: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showMenu: false,
    menuStateClass: 'mobile-header-menu__closed'
  }),
  computed: {
    hasAnyGiftCards() {
      return this.$activeGiftCard?.giftCards?.length > 0;
    },
    rootPathRedirectUrl() {
      return this.$channel.rootPathRedirectUrl || '';
    }
  },
  watch: {
    '$route.pathname'() {
      this.closeMenu();
    }
  },
  methods: {
    openMenu() {
      this.showMenu = true;
      this.menuStateClass = 'mobile-header-menu__open';
      setTimeout(() => {
        window.addEventListener(
          'resize',
          this.closeMenuIfMobileViewportBoundariesAreExceeded
        );
      }, 0);
    },
    closeMenuIfMobileViewportBoundariesAreExceeded(e) {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width > deviceWidths.tabletMaxWidth) {
        this.closeMenu();
      }
    },
    closeMenu() {
      if (this.showMenu) {
        window.removeEventListener(
          'resize',
          this.closeMenuIfMobileViewportBoundariesAreExceeded
        );
        this.menuStateClass = 'mobile-header-menu__closed';
        setTimeout(() => {
          this.showMenu = false;
        }, 250);
      }
    }
  }
};
</script>

<template>
  <div class="mobile-header">
    <div class="mobile-header__content">
      <img
        src="../../static/icons/hamburger.svg"
        @click="openMenu()"
        class="mobile-header__menu"
      />
      <div class="mobile-header__logo">
        <router-link :to="rootPathRedirectUrl" class="mobile-header__logo-link">
          <img class="mobile-header__logo-image" :src="logoUrl" />
        </router-link>
      </div>
      <div class="mobile-header__actions" v-if="hasAnyGiftCards">
        <favorites-header-link />
        <img
          src="../../static/icons/search.svg"
          class="mobile-header__search-icon"
          @click="$mitt.emit('Search:Open')"
        />
        <active-gift-card class="mobile-header__giftcard" />
      </div>
    </div>
    <DrawerLayer
      v-model="showMenu"
      :modalClass="menuStateClass"
      :showCloseIcon="false"
      key="mobile-header-menu__drawer"
    >
      <mobile-header-menu :links="links" @close="closeMenu" />
    </DrawerLayer>
  </div>
</template>

<style>
.mobile-header {
  padding: 10.5px 1rem;
  position: relative;
}

.mobile-header__content {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.mobile-header__logo {
  max-width: 75%;
  padding: 0;
}

.mobile-header__logo-link {
  display: flex;
  justify-content: center;
}

.mobile-header__logo-image {
  height: 20px;
  max-width: none;
}

.mobile-header__menu {
  height: 15px;
  display: flex;
}

.mobile-header__search-icon {
  cursor: pointer;
  height: 20px;
}

.mobile-header__menu-icon {
  padding-bottom: 3px;
}

.mobile-header__actions {
  display: grid;
  height: 42px;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}

.mobile-header-menu__open .drawer__container {
  transform: translateX(0%);
}

/* important used to override inline style on drawerlayer dependency */
.mobile-header-menu__closed .drawer__container {
  transform: translateX(-100%) !important;
}
</style>
