import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { request, query, sales } from '@motillo/drapejs-litium';
import { gql } from 'graphql-request';

export const giftCardFields = gql`
  fragment GiftCardFields on GiftCard {
    id
    type
    code
    countries {
      code
      name
    }
    categoryIds
    checkoutAddresses {
      collectionNo
      code
      name
      name2
      address
      address2
      postCode
      city
      country
    }
    balance
    inventory
  }
`;

export const commands = {
  activateGiftCard: defineCommand<{
    url: string;
    codes: string;
    clear: boolean;
  }>('activateGiftCard'),
  clearGiftCard: defineCommand<{
    url: string;
    giftCardId: string;
  }>('clearGiftCard')
};

export const receivers = {
  activateGiftCard: defineReceiver(
    commands.activateGiftCard,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation activateGiftCard(
            $url: String!
            $codes: String!
            $clear: Boolean
          ) {
            session(url: $url) {
              addGiftCard {
                addGiftCard(codes: $codes, clear: $clear) {
                  giftCards {
                    ...GiftCardFields
                  }
                  redirectTo
                  error
                  firstCategory {
                    categoryId
                    url
                  }
                  favoriteProductIds
                }
              }
            }
          }
          ${giftCardFields}
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          codes: command.codes || '',
          clear: command.clear || false
        }
      );

      await this.cache.setItem('__activeGiftCard', result.session.addGiftCard.addGiftCard);

      return result.session.addGiftCard.addGiftCard;
    },
    'litium'
  ),
  clearGiftCard: defineReceiver(
    commands.clearGiftCard,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation removeAllGiftCards($url: String!) {
              session(url: $url) {
                removeAllGiftCards{
                  removeAllGiftCards {
                    giftCards {
                      ...GiftCardFields
                    }
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
            ${giftCardFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`
        }
      );

      await this.cache.setItem('__activeGiftCard', {
        giftCards: result.session.removeAllGiftCards.removeAllGiftCards.giftCards || [],
        firstCategory: null
      });
      await this.cache.setItem(
        '__cart',
        result.session.removeAllGiftCards.removeAllGiftCards.cart
      );

      return result.session.removeAllGiftCards.removeAllGiftCards;
    },
    'litium'
  )
};
