<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

import useContext from '@/composables/useContext';

import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import Search from '@/components/search/Search.vue';
import { setCookieInformationScript } from '../functions/cookie.functions';

const isMounted = ref(false);
const { channel } = useContext();
watch(channel, (newVal, _) => {
  if (newVal != undefined) {
    setCookieInformationScript(newVal.language);
  }
});

onMounted(async () => {
  isMounted.value = true;
});
</script>

<template>
  <SiteHeader />
  <Search v-if="isMounted" />
  <router-page />
  <SiteFooter />
</template>
