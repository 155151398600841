<script setup lang="ts">
import { ref } from "vue";

import useCountrySwitcher from "@/composables/useCountrySwitcher";
const flags = require.context("../../static/flags", false, /\.svg$/);

const props = defineProps({
  rowClass: {
    type: String,
    default: "",
  },
  iconClass: {
    type: String,
    default: "",
  },
});

const { changeCountry, availableCountries } = useCountrySwitcher();

function getCountryDisplayName(country: any) {
  return country.name || country.channelName;
}

function getCountryFlag(country: any) {
  return {
    backgroundImage: `url('${flags(`./${country.id.toLowerCase()}.svg`)}')` || " ",
  };
}

async function onCountryClick(country: any) {
  try {
    const redirectUrl = await changeCountry(country.id);

    if (redirectUrl) {
      window.location.assign(redirectUrl);
    } else {
      window.location.reload();
    }
  } catch (err) {
    alert(err);
  }
}
</script>

<template>
  <div class="country-switcher">
    <div>
      <div
        v-for="country in availableCountries"
        :key="country.id"
        @click="onCountryClick(country)"
        :class="['country-switcher__item', rowClass]"
      >
        <div
          :style="getCountryFlag(country)"
          :class="['country-switcher__item-icon', iconClass]"
        ></div>
        <span class="country-switcher__item-text">{{
          getCountryDisplayName(country)
        }}</span>
      </div>
    </div>
  </div>
</template>

<style>
.country-switcher__item {
  font-family: "Mulish Medium", Arial, Helvetica, sans-serif;
  padding: 0.25rem 0;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.country-switcher__item-icon {
  width: 20px;
  height: 14px;
  border: 1px solid var(--color-gray-1440);
  margin-right: 6px;
  background-size: cover;
  background-repeat: no-repeat;
}

.country-switcher__item-text {
  padding-top: 1px;
}

@media (--desktop) {
  .country-switcher__item {
    font-size: 12px;
  }
}
</style>
