<template>
  <svg version="1.1" viewBox="0 0 66.667 66.667" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="clipPath26">
        <path d="m0 50h50v-50h-50z" />
      </clipPath>
    </defs>
    <g transform="matrix(1.3333 0 0 -1.3333 0 66.667)">
      <g clip-path="url(#clipPath26)">
        <g transform="translate(22.002 42.99)">
          <path d="m0 0c-8.285 7e-3 -15.008-6.704-15.015-14.989-6e-3 -8.285 6.704-15.007 14.99-15.015 8.285-7e-3 15.007 6.704 15.014 14.99v0.025c-0.034 8.264-6.725 14.954-14.989 14.989m11.75-32.66c-1.264-0.637-2.398-1.307-3.613-1.821-10.767-4.497-23.141 0.588-27.637 11.356s0.588 23.142 11.356 27.637c10.768 4.496 23.142-0.588 27.638-11.356 0.343-0.822 0.634-1.665 0.871-2.525 1.594-5.668 0.716-11.746-2.42-16.731-0.265-0.281-0.252-0.723 0.03-0.989 0.028-0.026 0.06-0.051 0.092-0.073 2.951-2.892 5.84-5.814 8.753-8.732 0.122-0.114 0.232-0.245 0.312-0.333l-5.903-5.903z" fill="currentColor" />
        </g>
      </g>
    </g>
  </svg>
</template>
