<script>

const flags = require.context('../../static/flags', false, /\.svg$/);

export default {
  props: {
    isCountrySwitcherVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    countryId() {
      if (this.$cart?.countryId == '001') { return 'EU'; }
      return this.$cart?.countryId;
    },
    countryFlag() {
      if (!this.countryId) return '';
      return `${flags(`./${this.countryId.toLowerCase()}.svg`)}`;
    },
  },
};
</script>
<template>
  <div class="active-country">
    <img :src="countryFlag" class="active-country__flag" />
    <div class="active-country__caption">
      {{countryId}}
    </div>
  </div>
</template>

<style>
  .active-country {
    user-select: none;
    cursor: pointer;
    display: flex;
  }

  .active-country__caption {
    font-family: "Mulish Medium", Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .active-country__flag {
    align-self: center;
    height: 20px;
    margin-right: 6px;
    border-radius: 2px;
  }
</style>
