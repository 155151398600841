import { fetchPageBuilder } from '@drapejs/core';
import useContext from '@/composables/useContext';
import { buildUrlPathWithQuery } from '@/helpers/url-utils';

export default function () {
  const { route, invoke } = useContext();

  async function executeCommand(command: any, args: any) {
    try {
      const request = buildRequest(args);
      const response = await invoke(command, request);
      return response;
    } catch (error) {
      return { error };
    }
  }

  function buildRequest(args: any) {
    const request = {
      ...args,
      ...fetchPageBuilder(route.protocol, route.host, route.pathname, { ...route.query }, ''),
    };
    request.url = buildUrlPathWithQuery(`${route.protocol}//${route.host}${route.pathname}`, route.query);
    return request;
  }

  return {
    executeCommand,
  };
}
