export const setCookieInformationScript = (language) => {
  var cookieScript = document.querySelector('#CookieConsent');
  if (cookieScript) {
    cookieScript.setAttribute('data-culture', language?.toUpperCase());
    cookieScript.setAttribute('data-gcm-version', '2.0');
    cookieScript.setAttribute(
      'src',
      'https://policy.app.cookieinformation.com/uc.js'
    );
  }
};
