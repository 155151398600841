<script>
import ActiveCountry from '@/components/country-switcher/ActiveCountry.vue';
import CountrySwitcher from '@/components/country-switcher/CountrySwitcher.vue';
import Logout from '@/components/header/Logout.vue';

export default {
  components: {
    ActiveCountry,
    CountrySwitcher,
    Logout
  },
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showCountrySwitcher: false
  }),
  computed: {
    hasAnyGiftCards() {
      return this.$activeGiftCard?.giftCards?.length > 0;
    }
  },
  methods: {
    toggleCountrySwitcher() {
      this.showCountrySwitcher = !this.showCountrySwitcher;
    },
    onPageLinkClicked(link) {
      if (document.location.href.indexOf(link?.url) > -1) {
        this.closeMenu();
      }
    },
    closeMenu() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="mobile-header-menu">
    <div class="mobile-header-menu__content">
      <div class="mobile-header-menu__actions">
        <img
          src="../../static/icons/close.svg"
          class="mobile-header-menu__close-icon"
          @click="closeMenu()"
        />
      </div>

      <div class="mobile-header-menu__items">
        <div class="mobile-header__links">
          <active-country
            :is-country-switcher-visible="showCountrySwitcher"
            class="mobile-header__link"
            @click.native="toggleCountrySwitcher()"
          />
          <country-switcher
            v-if="showCountrySwitcher"
            class="mobile-header__country-switcher"
            row-class="mobile-header__link mobile-header__country-switcher-link"
          />
          <div
            v-for="(link, index) in links"
            :key="index"
            class="mobile-header__link"
          >
            <router-link :to="link.url" @click.native="onPageLinkClicked(link)">
              {{ link.caption }}
            </router-link>
          </div>
          <logout
            v-if="hasAnyGiftCards"
            class="mobile-header__link mobile-header__logout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.mobile-header-menu {
  background-color: #ebe5da;
  height: 100%;
}

.mobile-header-menu__content {
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.mobile-header-menu__actions {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-header-menu__close-icon {
  color: var(--color-standard);
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.mobile-header-menu__items {
  display: flex;
  flex-direction: column;
  margin: 14px 15px 0;
}

.mobile-header__country-switcher {
  margin-left: 16px;
}

.mobile-header__link.mobile-header__country-switcher-link {
  margin-top: 0px;
  margin-bottom: 12px;
}

.mobile-header__links {
  display: flex;
  flex-direction: column;
}

.mobile-header__link {
  margin: 9px 0 20px;
  letter-spacing: 0.75px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}

.mobile-header__link,
.mobile-header__link a,
.mobile-header__link a:visited {
  text-decoration: none;
  color: var(--color-standard);
}

.mobile-header__logout {
  padding: 15px 0 0;
  margin: 6px 0 0;
  border-top: 1px solid var(--color-standard);
}
</style>
