import { InjectionKey, Ref } from 'vue';
import { Emitter } from 'mitt';

export const websiteTextsKey: InjectionKey<any> = Symbol('websiteTexts');
export const cartKey: InjectionKey<Ref<any>> = Symbol('cart');
export const emitterKey: InjectionKey<Emitter<any>> = Symbol('mitt');
export const channelKey: InjectionKey<Ref<any>> = Symbol('channel');
export const redirectToLoginPageKey: InjectionKey<(query: any) => Promise<void>> = Symbol('redirectToLoginPage');
export const countrySwitcherKey: InjectionKey<Ref<any>> = Symbol('countrySwitcher');
export const replaceTokensKey: InjectionKey<(text: string, args?: { [key: string]: string }) => string> = Symbol('replaceTokens');
export const headerKey: InjectionKey<Ref<any>> = Symbol('header');
export const footerKey: InjectionKey<Ref<any>> = Symbol('footer');

export const activeGiftCardKey: InjectionKey<Ref<any>> = Symbol('activeGiftCard');