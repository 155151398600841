import useContext from '@/composables/useContext';

export default function () {
  const { ga4, cart } = useContext();

  function reportRemoveFromCart(row: any) {
    if (row != null && row.articleNumber) {
      ga4.removeFromCart(cart.value?.currency?.id, [
        {
          item_id: row.articleNumber,
          item_name: row.variantName,
          price: row.unitPrice?.toString(),
          quantity: 1,
        },
      ]);
    }
  }

  function reportAddToCart(row: any) {
    if (row != null && row.articleNumber) {
      ga4.addToCart(cart.value?.currency?.id, [
        {
          item_id: row.articleNumber,
          item_name: row.variantName,
          price: row.unitPrice?.toString(),
          quantity: 1,
        },
      ]);
    }
  }

  return {
    reportAddToCart,
    reportRemoveFromCart,
  };
}
