<script setup lang="ts">
import { computed } from "vue";
import ProductCard from "../ProductCard.vue";

import { getContext } from "@/composables/useSearch";

const { searchResult } = getContext();

const products = computed(() => {  
  return searchResult.value?.products || [];
});
</script>

<template>
  <div class="search_result">
    <div class="search_result__product-list">
      <product-card
        v-for="(product, index) in products"
        :key="index"
        :product="product"
      />
    </div>
  </div>
</template>

<style>
.search_result {
  margin: 0 auto;
  max-width: var(--site-max-width);
}

.search_result__product-list {
  margin: auto -0.3rem;
  display: flex;
  flex-wrap: wrap;
}

.search_result__product-list .product-card {
  display: flex;
  flex-direction: column;
}

.search_result__product-list .product-card__info {
  flex-grow: 1;
}

@media (--phone) {
  .search_result__product-list .product-card {
    width: 50%;
  }
}

@media (--tablet) {
  .search_result__product-list .product-card {
    width: 25%;
  }
}

@media (--desktop) {
  .search_result__product-list .product-card {
    width: 16.66%;
  }
}
</style>
