<script>
  import ActiveCountry from '@/components/country-switcher/ActiveCountry.vue';
  import CountrySwitcher from '@/components/country-switcher/CountrySwitcher.vue';
  import Logout from '@/components/header/Logout.vue';

  export default {
    components: {
      ActiveCountry,
      CountrySwitcher,
      Logout,
    },
    props: {
      links: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      showCountrySwitcher: false,
    }),
    computed: {
      hasAnyGiftCards() {
        return this.$activeGiftCard?.giftCards?.length > 0;
      },
    },
    methods: {
      toggleCountrySwitcher() {
        this.showCountrySwitcher = !this.showCountrySwitcher;
      },
    },
  };
</script>

<template>
  <div class="desktop-header__links">
    <div
      v-for="(link, index) in links"
      :key="index"
      class="desktop-header__link">
        <router-link :to="link.url">{{ link.caption }}</router-link>
      </div>
      <div class="desktop-header__actions">
      <div
        v-if="hasAnyGiftCards"
        class="desktop-header__log-out">
        <logout />
        <div class="desktop-header__log-out-bar"></div>
      </div>
      <active-country
        :is-country-switcher-visible="showCountrySwitcher"
        @click.native="toggleCountrySwitcher()" />
      <country-switcher
        v-if="showCountrySwitcher"
        class="desktop-header__country-switcher"
        row-class="desktop-header__country-switcher-item" />
    </div>
  </div>
</template>

<style>
  .desktop-header__links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    position: relative;
    max-width: calc(var(--site-max-width) + 2rem);
    margin: auto;
    margin-bottom: 1rem;
  }

  .desktop-header__link {
    font-family: 'Mulish Medium', Arial, Helvetica, sans-serif;
    padding: 0 0.5rem;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .desktop-header__link a,
  .desktop-header__link a:visited {
    text-decoration: none;
    color: var(--color-black);
  }

  .desktop-header__actions {
    width: 21%;
    display: flex;
    position: absolute;
    right: 1rem;
    justify-content: flex-end;
    color: var(--color-black);
    height: 21px;
  }

  .desktop-header__country-switcher {
    position: absolute;
    right: 0;
    top: 30px;
    background-color: var(--color-white);
    color: var(--color-black);
    z-index: 1000;
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;
    box-shadow: var(--basic-shadow);
  }

  .desktop-header__country-switcher-item {
    padding-bottom: 0.5rem;
  }

  .desktop-header__country-switcher-item:hover {
    color: var(--color-gray-900);
  }

  .desktop-header__log-out {
    display: flex;
    align-items: center;
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
  }

  .desktop-header__log-out-bar {
    height: 21px;
    border-right: 1px solid var(--color-black);
    margin-left: 15px;
  }
</style>