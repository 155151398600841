<script lang="ts">
import { defineComponent } from "vue";
import LayoutRoot from "./layouts/LayoutRoot.vue";

export default defineComponent({
  name: "App",
  components: {
    LayoutRoot,
  }
});
</script>

<template>
  <router-view>
    <LayoutRoot />
  </router-view>
</template>
