import { inject, ref, ssrContextKey } from 'vue';
import { pageKey, routeKey, navigateKey, invokeKey, Route } from '@drapejs/core';
import { injectionKeys as litiumKeys } from '@motillo/drapejs-litium';
import { Emitter } from 'mitt';
import { replaceTokensKey, emitterKey, channelKey, cartKey, headerKey, footerKey } from '@/keys';
import { useGA4 } from '@motillo/tracking-gtm';

export default function () {
  const cart = inject(cartKey, ref<any>(<any>{}));
  const channel = inject(channelKey, ref<any>(<any>{}));

  function formatPrice(value: number, decimals = 2, locale?: string, currencyId?: string) {
    if (value !== 0 && !value) {
      return '';
    }
    if (!cart?.value) {
      return value;
    }
    return new Intl.NumberFormat(locale || channel.value?.locale || 'sv-SE', {
      style: 'currency',
      currency: currencyId || cart.value.currency?.id || 'SEK',
      maximumFractionDigits: decimals,
      minimumFractionDigits: 0,
    }).format(value);
  }

  return {
    cart,
    channel,
    formatPrice,
    page: inject(pageKey, ref()),
    header: inject(headerKey, ref<any>(<any>{})),
    footer: inject(footerKey, ref<any>(<any>{})),
    ssrContext: inject(ssrContextKey, <any>{}),
    route: inject(routeKey, <Route>(<any>{})),
    replaceTokens: inject(replaceTokensKey, () => ''),
    mitt: inject(emitterKey, <Emitter<any>>(<any>{})),
    invoke: inject(invokeKey, () => Promise.resolve()),
    navigate: inject(navigateKey, () => Promise.resolve()),
    toLitiumMediaUrl: inject(litiumKeys.toLitiumMediaUrlKey, () => ''),
    ga4: useGA4({
      useLegacyEnhancedECommerce: true
    }),
  };
}
